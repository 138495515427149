import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { useNavigate } from "react-router-dom";


function Popup(props) {
  const navigate = useNavigate();

    const handleUpdateStatus = (status) => {
            axios
            .patch(process.env.REACT_APP_BASEURL+"/v1/admin/appointment/update-appointment-status/" + props.appointmentId, {
                status: status
            }, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              }
            }).then(()=>{

                handleClose();
                props.handleSearch();
            }).catch(err=> {
              if(err.response.data.statusCode === 403) {
                localStorage.removeItem('token');
                navigate("/");
              }
            })

    }

  const handleClose = () => props.setViewOpen(false);

  return (
    <>
      <Modal show={props.viewOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.descData}</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={()=>{
            handleUpdateStatus(2)
          }}>
            Accept
          </Button>
          <Button variant="danger" onClick={()=>{
            handleUpdateStatus(9)
          }}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Popup;