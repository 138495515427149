import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Appointment from "./component/Appointment";
import Home from "./component/Home";
import GeneralQueries from "./component/GeneralQueries";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          
          <Route exact path="/" element={<Home />} />
          <Route exact path="/appointments" element={<Appointment />} />
          <Route exact path="/general-queries" element={<GeneralQueries />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
