import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Popup from "./Popup";

function Appointment() {
  const navigate = useNavigate();

  const [ showDropdown, setShowDropdown ] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
      return;
    } else {
      handleSearch();
    }
  }, []);
  const [appointments, setAppointments] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState(0);
  const [appointmentId, setAppointmentId] = useState(0);

  const [viewOpen, setViewOpen] = useState(false);

  const [descData, setDescData] = useState("");

  const handleSearch = () => {
    const filter = {};

    if (startDate) {
      filter.startDate = startDate;
    }

    if (endDate) {
      filter.endDate = endDate;
    }

    if (status === 1 || status === 2 || status === 9) {
      filter.status = status;
    }

    axios
      .post(
        process.env.REACT_APP_BASEURL + "/v1/admin/appointment/list",
        filter,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((resp) => {
        setStartDate("");
        setEndDate("");
        setStatus(0);
        setAppointments(resp.data.data);
      })
      .catch((err) => {
        if (err.response.data.statusCode === 403) {
          localStorage.removeItem("token");
          navigate("/");
        }
      });
  };

  return (
    <>
      <Navbar />
      {viewOpen && (
        <Popup
          viewOpen={viewOpen}
          setViewOpen={setViewOpen}
          descData={descData}
          appointmentId={appointmentId}
          handleSearch={handleSearch}
        />
      )}

      <div className="d-flex justify-content-evenly container mt-5">
        <div>
          <label>Start Date</label>
          <input
            type="date"
            aria-label="Start Date"
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            className="form-control"
          />
        </div>

        <div>
          <label>End Date</label>

          <input
            type="date"
            aria-label="End Date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="dropdown">
          <button className="dropbtn" onClick={() => setShowDropdown(true)}>Status</button>
          <div className="dropdown-content" style={{  display : showDropdown? 'block' : 'none' }}  >
            <a onClick={() => {
              setStatus(1)
              setShowDropdown(false)
            }} style={{ cursor: "pointer" }}>
              Pending
            </a>
            <a onClick={() => {
              setStatus(2)
              setShowDropdown(false)
            }} style={{ cursor: "pointer" }}>
              Accepted
            </a>
            <a onClick={() => {
              setStatus(9)
              setShowDropdown(false)
            }} style={{ cursor: "pointer" }}>
              Rejected
            </a>
          </div>
        </div>
        
        <div className="searchBtn">
        <button className="dropbtn" onClick={handleSearch}>
          Search
        </button>
        </div>

      </div>

      <div className="container-fluid mt-5">
        <table className="table">
          <thead>
            <tr>
              <th>UserName</th>
              <th>patient</th>
              <th>Mobile No</th>
              <th>Time of Booking</th>
              <th>DOB</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment, index) => (
              <tr key={index}>
                <td>{appointment?.userId?.name}</td>
                <td>{appointment.name}</td>
                <td>{appointment.mobileNo}</td>
                <td>
                  {new Date(appointment?.timeOfBooking).toLocaleTimeString() +
                    " " +
                    new Date(appointment?.timeOfBooking).toLocaleDateString(
                      "en-GB"
                    )}
                </td>
                <td>
                  {new Date(appointment?.dob).toLocaleDateString("en-GB")}
                </td>
                <td>
                  {appointment.status === 1
                    ? "PENDING"
                    : appointment.status === 2
                    ? "ACCEPTED"
                    : "REJECTED"}
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => {
                      setViewOpen(true);
                      setDescData(appointment.description);
                      setAppointmentId(appointment._id);
                    }}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Appointment;
