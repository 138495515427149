import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import Navbar from "./Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Home() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [isErr, setIsErr] = useState("none");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/appointments");
    }
  });
  const handleLogin = () => {
    axios
      .post(process.env.REACT_APP_BASEURL + "/v1/admin/auth/login", {
        username,
        password,
      })
      .then((resp) => {
        if (resp.data.data.status === 200) {
          localStorage.setItem("token", resp.data.data.token);
          navigate("/appointments");
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          setIsErr("block");
        }
      });
  };
  return (
    <>
      <Navbar />
      {/* <div
        class="alert alert-danger"
        style={{ display: `${isErr}` }}
        role="alert"
      >
        This is a danger alert—check it out!
      </div> */}

      <div class="alert alert-warning alert-dismissible fade show" style={{ display:`${isErr}` }} role="alert">
        <strong>Error</strong> Invalid Credentials
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={()=>{
            setIsErr('none')
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Container className="mt-5 w-25">
        <h2>Login</h2>
        <div>
          <input
            type="username"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="form-control"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control mt-4"
          />

          <Button variant="primary" className="mt-3" onClick={handleLogin}>
            Login
          </Button>
        </div>
      </Container>
    </>
  );
}

export default Home;
