import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function GeneralQueries() {
  const navigate = useNavigate();

  const [generalQueries, setGeneralQueries] = useState([]);

  useEffect(()=> {
    if(!localStorage.getItem('token')) {
      navigate('/')
      return;
    }
    axios
      .get(process.env.REACT_APP_BASEURL+"/v1/admin/appointment/list-general-queries", {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        }
      })
      .then((resp) => {
        setGeneralQueries(resp.data.data);
      }).catch(err=> {
        if(err.response.data.statusCode === 403) {
          localStorage.removeItem('token');
          navigate("/");
        }
      });
  }, [])

  
  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <table className="table">
          <thead>
            <tr>
              <th >UserName</th>
              <th >patient</th>
              <th >Mobile No</th>
              <th >Email</th>
              <th >Date & Time</th>
              <th >Description</th>
            </tr>
          </thead>
          <tbody>
            {generalQueries.map((appointment, index) => (
              <tr key={index}>
                <td>{appointment?.userId.name}</td>
                <td>{appointment.name}</td>
                <td>{appointment.mobileNo}</td>
                <td>{appointment.email}</td>
                <td>
                  {new Date(appointment?.createdAt).toLocaleTimeString()+ " " + (new Date(appointment?.createdAt).toLocaleDateString("en-GB"))}
                </td>
                <td>{appointment.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default GeneralQueries;
