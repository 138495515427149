import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";


const Navbar = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();


  const [appointmentStyle, setAppointmentStyle] = useState("intial");
  const [genQueryStyle, setGenQueryStyle] = useState("intial");
  const [homeStyle, setHomeStyle] = useState("intial");

  useEffect(() => {
    if (window.location.pathname === "/appointments") {
      setAppointmentStyle("underline");
      setGenQueryStyle("intial");
      setHomeStyle("intial");
    } else if (window.location.pathname === "/general-queries") {
      setAppointmentStyle("intial");
      setGenQueryStyle("underline");
      setHomeStyle("intial");
    } else if (window.location.pathname === "/") {
      setAppointmentStyle("intial");
      setGenQueryStyle("intial");
      setHomeStyle("underline");
    }
  });

  const handleLogout = () => {
    axios
    .post(process.env.REACT_APP_BASEURL+"/v1/admin/auth/logout", {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
    .then((resp) => {
      if(resp.data.statusCode === 200) {
        navigate('/')
        localStorage.removeItem('token')
      }
    }).catch(err=> {
      if(err.response.data.statusCode === 403) {
        localStorage.removeItem('token');
        navigate("/");
      }
    });

  }

  return (
    <nav className="navbar navbar-expand-lg navbar-info bg-info">
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          {!token && (
            <li className="nav-item">
              <Link className="nav-link" to="/">
                <label
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textDecoration: `${homeStyle}`,
                    cursor: 'pointer'

                  }}
                >
                  Home
                </label>
              </Link>
            </li>
          )}
          {token && (
            <li className="nav-item">
              <Link className="nav-link" to="/appointments">
                <label
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textDecoration: `${appointmentStyle}`,
                    cursor: 'pointer'

                  }}
                >
                  Appointments
                </label>
              </Link>
            </li>
          )}

          {token && (
            <li className="nav-item">
              <Link className="nav-link" to="/general-queries">
                <label
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textDecoration: `${genQueryStyle}`,
                    cursor: 'pointer'
                  }}
                >
                  GeneralQueries
                </label>
              </Link>
            </li>
          )}

          {token && (
            <li className="nav-item">
              <Link className="nav-link">
                <label
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '65px'
                  }}
                  onClick={handleLogout}
                >
                  LogOut
                </label>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
